




import { Component, Vue } from 'vue-property-decorator';
import AuthPasswordReset from '@/app/modules/auth/components/AuthRequestPasswordReset.vue';

@Component({
  components: {
    AuthPasswordReset,
  },
})
export default class AuthPasswordResetView extends Vue {
}
